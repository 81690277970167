import React from 'react'
import { ProjectsPageTemplate } from '../containers/Projects'

export default function ProjectsPage() {
  return (
    <ProjectsPageTemplate
      title="Projects"
      intro={
        <>
          These are various projects I have worked on! 
        </>
      }
      projects={{
        'smooth-doc': {
          label: 'wearable ASL translator',
          description: (
            <>
              ConchShell is a <strong>wearable ASL translator</strong> to help the speech-impaired communicate by <strong>converting ASL letters into a voice</strong>. I {' '} used <strong>Python, Google's open-source MediaPipe (largely in C++), and Pytorch </strong>for 
              a neural network that was trained on 30 000 samples of training data generated by myself and ASL users I contacted. I used a Raspberry Pi Zero W and camera for testing. My friend and I raised $16 600 in funding. We completed a prototype that operated with <strong>94% accuracy</strong> and underwent the DMZ start-up accelerator.
            </>
          ),
        },
        jamtemplates: {
          label: 'a statistical research paper exploring predictors of opinions on euthanasia in individuals',
          description: (
            <>
              I wrote a paper exploring factors correlating with <strong>certain opinions about euthanasia</strong>. I surveyed 72 people in Richmond Hill, Ontario, and used <strong> SPSS to run linear regressions </strong>to find significant predictors of whether someone would support or not support euthanasia. Examples of independent variables included whether one grew up in a religious family and how often they watched cable news.
            </>
          ),
        },
        svgr: {
          label: 'an LLM-powered educational chrome extension',
          description: (
            <>
              At the HackPrinceton hackathon, I built a <strong> chrome extension </strong> that allows you to pause a YouTube video, ask a question, and then have the extension respond using the YouTuber's voice. It is capable of answering your question, rewinding the video to a relevant time, or recommending another video. We used <strong> GPT-4 Vision, GPT-4, ElevenLabs, and retrieval-augmented generation </strong>.
            </>
          ),
        },
        'loadable-components': {
          label: 'research project using statistical methods to explore contributors to patient death from disease in Hanoi',
          description: (
            <>
              	I made use of descriptive statistics and linear and logistic regressions in R to find factors (e.g. resistance to antibiotics, type of pathogen, etc…) that contribute to various patient outcomes in a 300-person patient data set at the National Hospital of Tropical Disease in Hanoi, Vietnam.
            </>
          ),
        },
        xstyled: {
          label: 'a statistical research paper exploring why allergies are becoming more common?',
          description: (
            <>
             I published a research paper, "The Rise of Allergies: An Investigation on Why It Is Occurring and How to Stop It" in the Vanderbilt Young Scientist Research Journal. I surveyed <strong>150 people</strong> about their allergies and lifestyle during their gestation and childhood. I used <strong> SPSS to run linear regressions </strong>to find significant factors contributing to allergy development.
            </>
          ),
        },
        'smooth-ui': {
          label: 'a statistical research paper exploring how air pollutants impact health in different countries',
          description: (
            <>
              I conducted a study, "An Assessment of the Significance of Various Air Pollutants on Public Health on a Global Scale". I assessed the significance of <strong>various air pollutants on disability-adjusted life years (attributable to air pollution)</strong> and provides policy recommendations based on the economic strength of each country. I gathered data for <strong>5 air pollutants</strong> (nitrogen dioxide, sulfur dioxide, carbon dioxide, methane, fluorinated gases) as well as sector-specific data for carbon dioxide from reliable sources such as the WHO and WRI. 
            </>
          ),
        },
        'bundle-analyzer': {
          label: '',
          description: (
            <>
              Coming Soon
            </>
          ),
        },
        'jest-puppeteer': {
          label: '',
          description: (
            <>
             Coming Soon
            </>
          ),
        },
        shipit: {
          label: '',
          description: (
            <>
              Coming Soon
            </>
          ),
        },
      }}
    />
  )
}
